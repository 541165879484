import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";


// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
interface ErrorMessage {
    exemptInt: string,
    exemptAgriExpenses: string,
    exemptAgriInc: string,
    landDetails: string,
}

interface ErrorMessages {
    exemptInt: string,
    exemptAgriExpenses: string,
    exemptAgriInc: string,
    landDetails: string,
    otherExamptIncome: {
        [index: number]: string;
    },
    otherExamptIncome2: {
        [index: number]: string;
    },
}


interface RentErrorMessage {
    rent: string,
    deduction: string
}

interface LandsErrorMessage {
    name_of_district: string;
    pin_code: string;
    checkbox: string;
    irrigation: string
}

export interface InvalidResponseType {
    errors: string;
}

export interface ExemptError {
    errors: {
        message: string;
    }
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    addLandFn: any;
    closeDialog?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    dividendPeriods: any[];
    landDetails: any[];
    landId: number | null;
    dividendID: string;
    dividendPeriod1: string;
    dividendPeriod2: string;
    dividendPeriod3: string;
    dividendPeriod4: string;
    dividendPeriod5: string;
    dividendValue1: string;
    dividendValue2: string;
    dividendValue3: string;
    dividendValue4: string;
    dividendValue5: string;
    name_of_district: string;
    checkbox: string;
    irrigation: string;
    pin_code: string;
    addLandExe: boolean;
    activeStep: number;
    intDialog: boolean;
    dividendDialog: boolean;
    exemptDialog: boolean;
    rentDialog: boolean;
    otherDialog: boolean;
    dividend: string;
    isDividend: boolean;
    divExpenses: any
    divideDividends: boolean;
    giftDrop: boolean;
    unexplainedDrop: boolean;
    raceHorseDrop: boolean;
    errors: {
        name_of_employer: string;
        nature_of_employer: string;
        tan_of_employer: string;
        basic_salary: any,
        house_rent_allowance: any,
        leave_travel_allowance: any,
        pin_code: string;
        city: string;
        state: string;
        address_of_employer: string;
    };
    sumOfMoney: string;
    otherInc: any;

    passThroughInc: any;
    otherIncDataId: string;
    giftSumOfMoney: string;
    giftImmovable: string;
    giftConsideration: string;
    giftFairMarket: string;
    giftFairConsideration: string;
    unexpCashCredits: string;
    unexpSection69: string;
    unexpSection69A: string;
    unexpSection69B: string;
    unexpSection69C: string;
    unexpSection69D: string;
    incRaceHorseReceipts: string;
    incRaceHorseSection57: string;
    incRaceHorseSection58: string;
    incRaceHorseSection59: string;
    incRaceHorseRaceHorse: string;
    pension: string;
    familyPension: string;
    rent: any;
    deduction: any;
    otherRentData: boolean;
    rentId: string;
    otherInterestData: boolean;
    exemptInt: string;
    exemptAgriInc: string;
    exemptAgriExpenses: string;
    otherExemptIncomes: any;
    otherIntAddFields: any
    intInterest: string;
    interestID: string;
    exemptID: string;
    snack: boolean;
    snackError: boolean;
    intDeposit: string;
    intIT: string;
    intOtherSrc: string;
    otherIncomeErrorObj: any
    otherIncomeAmtError: any
    otherIncomeDescError: any
    errorType: any
    otherIntIncomeError: any
    otherIntIncomeDescError: any
    otherIntIncomeAmterror: any

    errorGroup: ErrorMessages,
    rentErrorGroup: RentErrorMessage,
    landsErrorGroup: LandsErrorMessage,
    dividendError: any,
    divExpensesError: any,
    otherDividentAmount: any,
    alert: any,
    alertRequired: any,
    alertFieldError: any
    snackMessage: string;
    totalInterestIncome: number;
    totalRentIncome: number;
    hasDividendError:boolean;
    totalDivident:number;
    authToken: "";
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}



export default class OtherIncomesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getOtherIncId: string = ''
    postIncDetailsId: string = '';
    updateIncDetailsId: string = '';
    deleteIncDetailsID: string = '';
    postInterestId: string = ''
    getInterestId: string = ''
    updateInterestId: string = '';
    deleteInterestId: string = '';
    updateRentId: string = '';
    deleteRentId: string = '';
    getRentId: string = ''
    postRentId: string = '';
    getExemptId: string = '';
    postExemptId: string = '';
    deleteSingleOtherExemptApiCallId: string = '';
    updateExemptId: string = '';
    postDividendId: string = '';
    getDividendId: string = '';
    updateDividendId: string = '';

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            checkbox: '',
            dividendID: '',
            dividendPeriod1: '',
            dividendPeriod2: '',
            dividendPeriod3: '',
            dividendPeriod4: '',
            dividendPeriod5: '',
            dividendValue1: '',
            dividendValue2: '',
            dividendValue3: '',
            dividendValue4: '',
            dividendValue5: '',
            otherExemptIncomes: [{
                description: '',
                amount: '',
            }],
            landDetails: [],
            landId: null,
            dividendPeriods: [
                'upto 15/6', '16/6 to 15/9', '16/9 to 15/12', '15/12 to 15/3', '16/3 and after'
            ],
            irrigation: '',
            name_of_district: '',
            pin_code: '',
            addLandExe: false,
            activeStep: 0,
            intDialog: false,
            dividendDialog: false,
            exemptDialog: false,
            rentDialog: false,
            otherDialog: false,
            dividend: '',
            isDividend: false,
            divExpenses: [{
                description: '',
                amount: '',
                error: null,
                descError: null
            }],
            divideDividends: false,
            giftDrop: false,
            unexplainedDrop: false,
            raceHorseDrop: false,
            errors: {
                name_of_employer: "",
                nature_of_employer: "",
                tan_of_employer: "",
                basic_salary: "",
                house_rent_allowance: "",
                leave_travel_allowance: "",
                pin_code: "",
                city: "",
                state: "",
                address_of_employer: ""
            },
            sumOfMoney: '',
            otherInc: [
                {
                    description: '',
                    amount: '',
                    income_type: "other_income"
                }
            ],
            passThroughInc: {
                desc: '',
                amt: '',
            },
            otherIncDataId: '',
            giftSumOfMoney: '',
            giftImmovable: '',
            giftConsideration: '',
            giftFairMarket: '',
            giftFairConsideration: '',
            unexpCashCredits: '',
            unexpSection69: '',
            unexpSection69A: '',
            unexpSection69B: '',
            unexpSection69C: '',
            unexpSection69D: '',
            incRaceHorseReceipts: '',
            incRaceHorseSection57: '',
            incRaceHorseSection58: '',
            incRaceHorseSection59: '',
            incRaceHorseRaceHorse: '',

            pension: '',
            familyPension: '',
            rent: [
                {
                    description: '',
                    amount: '',
                    rent_type: 'rent'
                }
            ],
            deduction: [{
                description: '',
                amount: '',
                rent_type: 'deduction'
            }],
            otherRentData: false,
            rentId: '',
            otherInterestData: false,
            exemptInt: '',
            exemptAgriInc: '',
            exemptAgriExpenses: '',
            otherIntAddFields: [
                {
                    description: '',
                    amount: '',
                }
            ],
            intInterest: '',
            interestID: '',
            exemptID: '',
            intDeposit: '',
            snack: false,
            snackError: false,
            intIT: '',
            intOtherSrc: '',
            otherIncomeErrorObj: {},
            otherIncomeAmtError: [],
            otherIncomeDescError: [],
            errorType: "success",
            errorGroup: {
                exemptInt: "",
                exemptAgriExpenses: "",
                exemptAgriInc: "",
                landDetails: "",
                otherExamptIncome: {},
                otherExamptIncome2: {},
            },
            rentErrorGroup: {
                rent: "",
                deduction: ""
            },
            landsErrorGroup: {
                name_of_district: "",
                pin_code: "",
                checkbox: "",
                irrigation: ""
            },
            otherIntIncomeError: {},
            otherIntIncomeDescError: [],
            otherIntIncomeAmterror: [],
            dividendError: null,
            divExpensesError: null,
            otherDividentAmount: {
                dividendValue1: null,
                dividendValue2: null,
                dividendValue3: null,
                dividendValue4: null,
                dividendValue5: null
            },
            alert: false,
            alertRequired: false,
            alertFieldError: false,
            snackMessage: "",
            totalInterestIncome: 0,
            totalRentIncome:0,
            hasDividendError:false,
            totalDivident:0,
            authToken: "",
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }



    async componentDidMount() {
        // Customizable Area Start
        this.getOtherInt();
        this.getOtherInc();
        this.getOtherRent();
        this.getDividend()
        this.getOtherExemptIncomes();

        const token = await getStorageData(configJSON.authToken);
        this.setState({
            authToken: token,
        });
        // Customizable Area End
    }



    // Customizable Area Start
    handlegetExemptId = (responseJson: any) => {
        if (responseJson?.data?.length > 0) {
            this.handleExemptIncomesData(responseJson)
        }
    }
    handgetRentId = (responseJson: any) => {
        let data = responseJson && responseJson.rents && responseJson.rents.data;
        if (data) {
            this.handleGetOtherRentData(responseJson)
        }
    }
    handlegetDividendId = (responseJson: any) => {
        if (responseJson.data.length > 0) {
            this.handleGetDividendData(responseJson)
        }
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (this.getInterestId === apiRequestCallId) {
                this.handleGetOtherInterestData(responseJson)
            }
            if (this.getExemptId === apiRequestCallId) {
                this.handlegetExemptId(responseJson)
            }
            if (this.getRentId === apiRequestCallId) {
                this.handgetRentId(responseJson)
            }
            this.apiResponse(apiRequestCallId)
            if (this.getDividendId === apiRequestCallId) {
                this.handlegetDividendId(responseJson)
            }
            if (this.getOtherIncId === apiRequestCallId) {
                this.handleGetOtherIncData(responseJson)
            }
            else {
                console.log(errorReponse, 'error123')
            }
             if (this.isInValidResponse(responseJson)) {
                this.apifailResponse(apiRequestCallId)
            }

        }

        // Customizable Area End
    }
    
    isInValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiResponse = (apiRequestCallId: any) => {
        if (this.postInterestId === apiRequestCallId) {

            this.setState({ snack: true,intDialog: false  })
        }
        if (this.postDividendId === apiRequestCallId) {
            this.setState({ snack: true })
        }
        if (this.postExemptId === apiRequestCallId) {
            this.getOtherExemptIncomes();
            this.setState({
                snack: true,
                errorType: configJSON.errorTypeSuccess,
                snackMessage: configJSON.savedSuccessMessage
            });
        }
        if (this.postRentId === apiRequestCallId) {
            this.setState({ snack: true, snackMessage: "data saved successfuly" , rent:[{ description: '', amount: '', rent_type: 'rent' }] , deduction:[{ description: '', amount: '', rent_type: 'deduction' }]})
            setTimeout(() => {
                this.props.addLandFn()
            }, 1000);        
        }
        if (this.postIncDetailsId === apiRequestCallId) {
            this.setState({ snack: true, errorType: "success" })
        }
        else {
            this.setState({ snackError: false })
        }
    }

    apifailResponse = (apiRequestCallId: string) => {
        if (this.postExemptId === apiRequestCallId) {
            this.setState({
                snack: true,
                errorType: configJSON.errorTypeForError,
                snackMessage: configJSON.panUserProfile
            });
        }
    };

    handleMessageClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ snack: false })
    };


    handleGetOtherRentData = (responseJson: any) => {
        const data = responseJson?.rents?.data?.attributes?.dropdown_items

        const deduction = data.filter((item: any) => item.rent_type === 'deduction')
        const rent = data.filter((item: any) => item.rent_type === 'rent')
        
        let totalRent = rent.reduce((accumulator: any, current: any) => accumulator + (current.amount ?? 0), 0);
        let totalDeduction = deduction.reduce((accumulator: any, current: any) => accumulator + (current.amount ?? 0), 0);
    
        let totalRentIncome = totalRent - totalDeduction;
        this.setState({ rent, deduction, otherRentData: true, rentId: responseJson?.rents?.data?.id,totalRentIncome })
    }

    handleGetOtherInterestData = (responseJson: any) => {
        if (responseJson && responseJson?.data?.length > 0) {

            const data = responseJson?.data[0].attributes
            const { saving_bank_interest, interest_from_deposits, interest_from_income_tax_refund,interest_from_any_other_source,Dropdown_items } = data
            let total = (saving_bank_interest??0) + (interest_from_deposits??0) + (interest_from_income_tax_refund??0) + (interest_from_any_other_source??0);
            let expenses = Dropdown_items ? Dropdown_items.reduce((accumulator:any, current:any) => accumulator + current.amount,0) : 0;

            this.setState({
                intInterest: saving_bank_interest,
                intDeposit: interest_from_deposits,
                intIT: interest_from_income_tax_refund,
                intOtherSrc: interest_from_any_other_source,
                otherIntAddFields: Dropdown_items,
                interestID: responseJson?.data[0]?.id,
                otherInterestData: true,
                totalInterestIncome: total-expenses
            })
        }

    }

    handleExemptIncomesData = (responseJson: any) => {
        const data = responseJson?.data[0]?.attributes
        const { exampt_interest, agricultural_expenses, agricultural_income, lands, Dropdown_items } = data

        this.setState({
            exemptInt: exampt_interest, exemptAgriExpenses: agricultural_expenses,
            exemptAgriInc: agricultural_income, landDetails: lands,
            otherExemptIncomes: Dropdown_items, exemptID: responseJson?.data[0]?.id
        })
    }

    handleChange = (state: any, value: any,) => {
        if(/[^0-9\b]/.test(value) || value.length > 12) {
            return
        }
        this.setState((prevState) => ({
            ...prevState,
            [state]: value,
        }));
    }
    handleSetDividentValue = (state: any, value: any,) => {
 
        this.setState((prevState) => ({
            ...prevState,
            [state]: value,
        }));
        if (!/^\d+$/.test(value) && value !== "") {
            this.setState(prevState => ({
                otherDividentAmount: {
                    ...prevState.otherDividentAmount,
                    [state]: "Please enter valid number"
                }
            }))
        } else {
            this.setState(prevState => ({
                otherDividentAmount: {
                    ...prevState.otherDividentAmount,
                    [state]: null
                }
            }))
        }
    }
    handleSetpassThroughIncAmt = (e: any) => {
        let value = e.target.value;
        if(/[^0-9\b]/.test(value) || value.length > 12) {
            return
        }
        this.setState({ passThroughInc: { ...this.state.passThroughInc, amt: e.target.value } });
    }
    handleSetpassThroughIncDesc = (e: any) => {
        let value = e.target.value;
        let val = value.replace(/^\s+/, "").replace(/\s{2,}/g, ' ');
        if(!/^[a-zA-Z0-9 ]*$/.test(value) || value.length > 50) {
            return
        }
        this.setState({ passThroughInc: { ...this.state.passThroughInc, desc: val } })
    }
    handleExemptChange = (name: string, value: any) => {
        const numericValue = value?.replace(/[^0-9.]/g, '');
        this.setState((prevData) => ({
            ...prevData,
            [name]: numericValue
        }), () => {
            this.getExempIncomeValidation()
        })
    }

    handleLandsChange = (state: any, value: any,) => {
        if (state === "pin_code") {
            const numericValue = value.replace(/\D/g, '').slice(0, 6);
            this.setState((prevState) => ({
                ...prevState,
                [state]: numericValue,
            }), () => {
                this.getaddLandsValidation()
            });
        } else {
            const stringValue = value.replace(/[^a-zA-Z]/g, ''); 
            this.setState((prevState) => ({
                ...prevState,
                [state]: stringValue,
            }), () => {
                this.getaddLandsValidation()
            });
        }
    }

    addField = () => {
        this.setState(prevState => ({
            rent: [...prevState.rent, { description: '', amount: '', rent_type: 'rent', error:'All Field is required' }]
        }), () => this.getRentValidation());
    }

    addExemptIncomes = () => {
        this.setState(prevState => ({
            otherExemptIncomes: [...prevState.otherExemptIncomes, { id: `temp-${Date.now()}`,  description: '', amount: '' }]
        }));
    }

    addDeduction = () => {
        this.setState(prevState => ({
            deduction: [...prevState.deduction, { description: '', amount: '', rent_type: 'deduction' , error :"All Field is required" }]
        }), () => this.getRentValidation());
    }

    addInterestFields = () => {
        this.setState(prevState => ({
            otherIntAddFields: [...prevState.otherIntAddFields, { description: '', amount: '' }]
        }));
    }

    addExpFields = () => {
        this.setState(prevState => ({
            divExpenses: [...prevState.divExpenses, { description: '', amount: '', error: null }]
        }));
    }

    handleOtherIncChangeDesc(index: any, value: any) {
        const val = value.replace(/^\s+/, "").replace(/\s{2,}/g, ' ');
        if (!/^[a-zA-Z0-9 ]*$/.test(value) || value.length > 50) {
            return; 
        }
    
        const otherInc = [...this.state.otherInc];
        otherInc[index].description = val;
    
        let otherIncomeDescError = [...this.state.otherIncomeDescError];
        if (val === "") {
            otherIncomeDescError = otherIncomeDescError.filter(e => e.index !== index);
        }
    
        this.setState({
            otherInc: otherInc,
            otherIncomeDescError: otherIncomeDescError
        });
    }
    
    

    handleOtherIncChangeAmt(index: any, value: any) {
        if (/[^0-9\b]/.test(value) || value.length > 12) {
            return;
        }
    
        const otherInc = [...this.state.otherInc];
        otherInc[index].amount = value;
    
        let otherIncomeAmtError = [...this.state.otherIncomeAmtError];
        otherIncomeAmtError = otherIncomeAmtError.filter(e => e.index !== index);
    
        this.setState({
            otherInc: otherInc,
            otherIncomeAmtError: otherIncomeAmtError
        });
    }
    
    

    handleOnChange = (event: any) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }
    getAvailableOptions = (excludePeriod:any) => {
        const { dividendPeriods } = this.state;
        const selectedPeriods = [
          this.state.dividendPeriod1,
          this.state.dividendPeriod2,
          this.state.dividendPeriod3,
          this.state.dividendPeriod4,
          this.state.dividendPeriod5
        ].filter(period => period && period !== excludePeriod);
    
        return [ ...dividendPeriods.filter(period => !selectedPeriods.includes(period))];
      }

    handleSetErrorDescMessage = (errorData: any, errormessage: any, index: any) => {
        for (const element of errorData) {
            if (element.index === index) {
                element.error = errormessage
            }
        }
        this.setState({
            otherIntIncomeDescError: errorData
        })
    }
    handleOtherInterestChangeDesc = (index: any, value: any) => {
        const otherIntAddFields = [...this.state.otherIntAddFields];
        let errorData = this.state.otherIntIncomeDescError;
    
        const specialCharacters = /[!@#$%^&*(),.?":{}|<>=\-+_]/g;
    
        const filteredValue = value.replace(specialCharacters, '');
    
        const trimmedFilteredValue = filteredValue.trimStart();
    
        if (trimmedFilteredValue.length <= 50) {
            otherIntAddFields[index].description = trimmedFilteredValue;
        }
    
        this.setState((prev) => ({
            ...prev, otherIntAddFields: otherIntAddFields
        }));
    
        if (trimmedFilteredValue === "" || value.trim() === "") {
            this.handleSetErrorDescMessage(errorData, "Required!", index);
        } else if (trimmedFilteredValue.length > 50) {
            this.handleSetErrorDescMessage(errorData, "Exceeds 50 characters!", index);
        } else {
            this.handleSetErrorDescMessage(errorData, null, index);
        }
    }
    handleSetErrorAmtMessage = (errorData: any, errormessage: any, index: any) => {
        console.log("otherIntIncomeAmterror", this.state.otherIntIncomeAmterror);

        for (const element of errorData) {
            if (element.index === index) {
                element.error = errormessage
            }
        }
        this.setState({
            otherIntIncomeAmterror: errorData
        })
    }
    handleOtherInterestAmt = (index: any, value: any) => {
        const otherIntAddFields = [...this.state.otherIntAddFields]
        otherIntAddFields[index].amount = value;
        let errorData = this.state.otherIntIncomeAmterror
        this.setState((prev) => ({
            ...prev, otherIntAddFields: otherIntAddFields
        }))
        if (value === "" || value === null) {
            this.handleSetErrorAmtMessage(errorData, "Required!", index)
        } else if (!/^\d+$/.test(value)) {
            this.handleSetErrorAmtMessage(errorData, "Please enter a valid number", index)
        } else {
            this.handleSetErrorAmtMessage(errorData, null, index)
        }
    }

    isValidString = (str:string):boolean => {
        const lastChar = str.trim().slice(-1);
        const specialCharacters = /[!@#$%^&*(),.?":{}|<>=\-+_]/;
        return specialCharacters.test(lastChar);

    }

    divExpensesDesc = (index: any, value: any) => {
        const divExpenses = [...this.state.divExpenses];
        let updatedValue = value;
        if(this.isValidString(updatedValue) && value !== "") {
            updatedValue= updatedValue.trim().slice(0,-1);
            this.setState((prev) => ({
                ...prev, divExpenses: divExpenses
            }))
        } else {
            divExpenses[index].descError = null
             this.setState((prev) => ({
                 ...prev, divExpenses: divExpenses
             }))
        }
        if (divExpenses[index]) divExpenses[index].description = updatedValue;
        this.setState((prev) => ({
            ...prev, divExpenses: divExpenses
        }))
    }

    divExpensesAmt = (index: any, value: any) => {
        const divExpenses = [...this.state.divExpenses]
        if (!/^\d+$/.test(value) && value !== "") {
            divExpenses[index].error = "Please enter valid number"
             this.setState((prev) => ({
                 ...prev, divExpenses: divExpenses
             }))
        } else {
            divExpenses[index].error = null
             this.setState((prev) => ({
                 ...prev, divExpenses: divExpenses
             }))
        }
        if (divExpenses[index]) divExpenses[index].amount = value
        this.setState((prev) => ({
            ...prev, divExpenses: divExpenses
        }))
    }

    handleOtherRentChangeDesc(index: any, value: any) {
        const stringValue = value.replace(/[^a-zA-Z]/g, '');
        const rent = [...this.state.rent]
        if (rent[index]?.description !== undefined) {
            rent[index].description = stringValue;
        }
        this.setState((prev) => ({
            ...prev, rent: rent
        }), () => this.getRentValidation())
    }

    handleOtherRentChangeAmt(index: any, value: any) {
        const numericValue = value.replace(/[^0-9.]/g, '');
        const rent = [...this.state.rent]
        if (rent[index]?.amount !== undefined) {
            rent[index].amount = numericValue;
        }
        this.setState((prev) => ({
            ...prev, rent: rent
        }), () => this.getRentValidation())
    }

    handleOtherExemptChangeDesc(index: any, value: any) {
        const stringValue = value.replace(/[^a-zA-Z]/g, '');
        const otherExemptIncomes = [...this.state.otherExemptIncomes]
        if (otherExemptIncomes[index]?.description !== undefined) {
            otherExemptIncomes[index].description = stringValue;
        }
        this.setState((prev) => ({
            ...prev, otherExemptIncomes: otherExemptIncomes
        }), () => this.getExempIncomeValidation())
    }

    handleOtherExemptChangeAmt(index: any, value: any) {
        const numericValue = value.replace(/[^0-9.]/g, '');
        const otherExemptIncomes = [...this.state.otherExemptIncomes]
        if (otherExemptIncomes[index]?.amount !== undefined) {
            otherExemptIncomes[index].amount = numericValue;
        }
        this.setState((prev) => ({
            ...prev, otherExemptIncomes: otherExemptIncomes
        }), () => this.getExempIncomeValidation())
    }

    handleDeductionDesc(index: any, value: any) {
        const stringValue = value.replace(/[^a-zA-Z]/g, '');
        const deduction = [...this.state.deduction]
        if (deduction[index]) (deduction[index].description = stringValue);
        this.setState((prev) => ({
            ...prev, deduction: deduction
        }), () => this.getRentValidation())
    }

    handleDeductionAmt(index: any, value: any) {
        const numericValue = value.replace(/[^0-9.]/g, '');
        const deduction = [...this.state.deduction]
        if (deduction[index]) (deduction[index].amount = numericValue);
        this.setState((prev) => ({
            ...prev, deduction: deduction
        }), () => this.getRentValidation())
    }

    rentValidateOtherExampIncomeForm = (rent:any) => {
       let checkedRent = rent.map((item:any)=>{
            if(item.description.trim() !== '' && item.amount.toString().trim() !== ''){
                return {...item, error:null}
            }else{
                return {...item, error:configJSON.otherExamptField}
            }
        })
        this.setState({rent: checkedRent})
        const isRentIncomeFormValid = this.state.rent.every(
            (item: any) => item.description.trim() !== '' && item.amount.toString().trim() !== ''
        );
        return isRentIncomeFormValid
    };

    itemCondition = (item: { amount: string | number } ) => {
        return item && item.amount && item.amount.toString() && item.amount.toString().trim()
    }

    deductionValidateOtherExampIncomeForm = (deduction:any) => {
        let checkedRent = deduction.map((item:any)=>{
            if(item.description.trim() !== '' && this.itemCondition(item) !== ''){
                return {...item, error:null}
            }else{
                return {...item, error:configJSON.otherExamptField}
            }
        })
        this.setState({deduction: checkedRent})
        const isRentIncomeFormValid = this.state.deduction.every(
            (item: any) => item.description.trim() !== '' && this.itemCondition(item) !== ''
        );
        return isRentIncomeFormValid
    };


    rentValidateField = (fieldName: string) => {
        const { rentErrorGroup, rent, deduction } = this.state;
        if (fieldName === "rent") {
            if (rent.length === 0) {
                rentErrorGroup[fieldName as keyof RentErrorMessage] = configJSON.rentFields;
            } else if (!this.rentValidateOtherExampIncomeForm(rent)) {
                rentErrorGroup[fieldName as keyof RentErrorMessage] = configJSON.otherExamptField;
            } else {
                delete rentErrorGroup[fieldName as keyof RentErrorMessage];
            }
        }
        if (fieldName === "deduction") {
            if (!this.deductionValidateOtherExampIncomeForm(deduction)) {
                rentErrorGroup[fieldName as keyof RentErrorMessage] = configJSON.otherExamptField;
            } else if (deduction.length === 0) {
                rentErrorGroup[fieldName as keyof RentErrorMessage] = configJSON.deductionFields;
            } else {
                delete rentErrorGroup[fieldName as keyof RentErrorMessage];
            }
        }
        this.setState({ rentErrorGroup });
    }

    getRentValidation = () => {
        let isValidate = true;
        this.rentValidateField('rent');
        this.rentValidateField('deduction');
        const { rentErrorGroup } = this.state;
        console.log(rentErrorGroup,"rentErrorGroup");
        
        if (Object.keys(rentErrorGroup).length > 0) {
            isValidate = false;
        }
        return isValidate;
    }

    addFieldIncome = () => {
        this.setState(prevState => ({
            otherInc: [...prevState.otherInc, { desc: '', amt: '', income_type: "other_income" }]
        }));

    }

    deleteFieldInc(index: any) {
        this.setState((prev) => {
            const del = [...prev.otherInc];
            del.splice(index, 1);
            return { otherInc: del }
        })
    }

    deleteFieldRent(index: any) {
        this.setState((prev) => {
            const del = [...prev.rent];
            del.splice(index, 1);
            return { rent: del }
        }, () => this.getRentValidation());

    }

    deleteFieldDeduction(index: any) {
        this.setState((prev) => {
            const del = [...prev.deduction];
            del.splice(index, 1);
            return { deduction: del }
        }, () => this.getRentValidation());

    }

    deleteOtherExempts = (index: number, itemId: number) => {
        if(itemId){
            this.handleDeleteSingleOtherExempt(itemId)
        }
        this.setState((prevState: { otherExemptIncomes: { id?: number; description: string; amount: string | number; }[] }) => {
            const updatedList = prevState.otherExemptIncomes.filter(
                item => item.id !== itemId || item.id === undefined
            );
            return { otherExemptIncomes: updatedList };
        }, () => { this.getExempIncomeValidation(); });
    }

    deleteLandDetails = (index: any) => {
        this.setState((prev) => {
            const del = [...prev.landDetails];
            del.splice(index, 1);
            return { landDetails: del }
        });
    }

    editLandDetails = (index: any) => {
        this.setState({
            landId: index + 1,
            name_of_district: this.state.landDetails[index].name_of_district,
            pin_code: this.state.landDetails[index].pin_code,
            checkbox: this.state.landDetails[index].checkbox,
            irrigation: this.state.landDetails[index].irrigation,
            addLandExe: true
        })
    }


    deleteFieldInterest = (index: any) => {
        this.setState((prev) => {
            const del = [...prev.otherIntAddFields];
            del.splice(index, 1);
            return { otherIntAddFields: del }
        });
    }

    deleteFieldExp = (index: any) => {
        this.setState((prev) => {
            const del = [...prev.divExpenses];
            del.splice(index, 1);
            return { divExpenses: del }
        });
    }

    deletePassThroughInc = () => {

        this.setState({
            passThroughInc: { ...this.state.passThroughInc, desc: '', amt: '' }
        });
    }

    handleGetDividendData = (responseJson: any) => {
        const data = responseJson?.data[0].attributes;
        const { dividend_toggle_sum, Dropdown_items, id } = data
        this.setState({
            dividend: data.dividend,
            divideDividends: dividend_toggle_sum ? true : false,
            divExpenses: Dropdown_items,
            dividendPeriod1: data?.toggle_dropdown[0]?.period,
            dividendPeriod2: data?.toggle_dropdown[1]?.period,
            dividendPeriod3: data?.toggle_dropdown[2]?.period,
            dividendPeriod4: data?.toggle_dropdown[3]?.period,
            dividendPeriod5: data?.toggle_dropdown[4]?.period,
            dividendValue1: data?.toggle_dropdown[0]?.amount,
            dividendValue2: data?.toggle_dropdown[1]?.amount,
            dividendValue3: data?.toggle_dropdown[2]?.amount,
            dividendValue4: data?.toggle_dropdown[3]?.amount,
            dividendValue5: data?.toggle_dropdown[4]?.amount,
            dividendID: id,
            totalDivident:Number(data?.dividend_sum)
        })
    }
    otherDividentAmountCheck() {
        const { otherDividentAmount } = this.state;
        for (const key in otherDividentAmount) {
            const value = otherDividentAmount[key]
            if (value !== null) {
                return true;
            }
        }
    }
    handleDividendValue = () => {
        const { dividendValue1, dividendValue2, dividendValue3, dividendValue4, dividendValue5 } = this.state;
        if (dividendValue1 === "" && dividendValue2 === "" && dividendValue3 === "" && dividendValue4 === "" && dividendValue5 === "") {
            return "";
        }
    }
    divExpensesCheck = () => {
        const { divExpenses } = this.state;
        let divLength = divExpenses.length;
         let EmptyAmounts = 0;
        if (divExpenses.length !== 0) {
        const { divExpenses } = this.state;
            for (const i in divExpenses) {
              if ( !divExpenses[i].error) {
                if (divExpenses[i].amount === "") {
                    EmptyAmounts++;
                }
                if (EmptyAmounts === divLength) {
                    return "";
                }
               
              }
              else {
                return true;
              }
            }
        }
    }
    divExpensesLength1 = () => {
        const { dividendError, dividend } = this.state;
        let divExpensesCheck = this.divExpensesCheck()
        let handleDividendValue = this.handleDividendValue()
        let CheckotherDividentAmount = this.otherDividentAmountCheck()
        if ( dividend === "" && divExpensesCheck === "" && handleDividendValue === "" ) {
            this.setState({ snack: true })
            this.setState({ alert: false })
            this.setState({ alertRequired: true })
            this.setState({ alertFieldError: null })
            return true;
        }
        else if ( CheckotherDividentAmount === true || dividendError !== null ||
            divExpensesCheck === true ) {
            this.setState({ snack: true })
            this.setState({ alert: false })
            this.setState({ alertRequired: false,hasDividendError:false })
            this.setState({ alertFieldError: false })
            return true;
        }
    }
    divExpensesLength0 = () => {
        const { dividendError, dividend } = this.state;
        let CheckotherDividentAmount = this.otherDividentAmountCheck()
        let handleDividendValue = this.handleDividendValue()
        if (dividend === "" && handleDividendValue === "") {
            this.setState({ snack: true })
            this.setState({ alert: false })
            this.setState({ alertRequired: true })
            this.setState({ alertFieldError: null })
            return true;
        } else if (CheckotherDividentAmount === true || dividendError !== null) {
            this.setState({ snack: true })
            this.setState({ alert: false })
            this.setState({ alertRequired: false,hasDividendError:false })
            this.setState({ alertFieldError: false })
            return true;
        }
    }
    validateDividendPairs = () => {
        const pairs = [
            { period: this.state.dividendPeriod1, value: this.state.dividendValue1 },
            { period: this.state.dividendPeriod2, value: this.state.dividendValue2 },
            { period: this.state.dividendPeriod3, value: this.state.dividendValue3 },
            { period: this.state.dividendPeriod4, value: this.state.dividendValue4 },
            { period: this.state.dividendPeriod5, value: this.state.dividendValue5 },
        ];
    
        const hasEmptyPair = pairs.map(({ period, value }) => {
            const isEmptyPeriod = period === '';
            const isEmptyValue = value === '';
        
            return !(isEmptyPeriod && isEmptyValue)&& ((isEmptyPeriod && !isEmptyValue) || (!isEmptyPeriod && isEmptyValue));
        }).includes(true);
        if (hasEmptyPair) {
            this.setState({
                snack: true,
                alert: false,
                alertRequired: true,
                alertFieldError: null
            });
            return true;
        }
        else
        {
            const currentDividendValues = {
                dividendValue1: Number(this.state.dividendValue1) || 0,
                dividendValue2: Number(this.state.dividendValue2) || 0,
                dividendValue3: Number(this.state.dividendValue3) || 0,
                dividendValue4: Number(this.state.dividendValue4) || 0,
                dividendValue5: Number(this.state.dividendValue5) || 0,
            };
            
            const newSum = 
                currentDividendValues.dividendValue1 +
                currentDividendValues.dividendValue2 +
                currentDividendValues.dividendValue3 +
                currentDividendValues.dividendValue4 +
                currentDividendValues.dividendValue5;
            if(Number(newSum) > Number(this.state.dividend)) {         
                this.setState({hasDividendError:true,  snack: true, alert:false, alertRequired:false})
                return true
            }
        }
    
        return false;
    }
    saveDividend = () => {
        const { divExpenses } = this.state;
        if (divExpenses.length !== 0) {
            if (this.divExpensesLength1()) {
                return;
            }
        } else {
            if (this.divExpensesLength0()) {
                return;
            }
        }
        if (this.state.divideDividends) {
            if (this.validateDividendPairs()) {
                return;
            }
        }
            this.setState({ snack: true })
            this.setState({ alert: true })
            this.setState({ alertRequired: false ,hasDividendError:false})
            this.setState({ alertFieldError: true })
        const header = {
            'token': localStorage.getItem(configJSON.authToken),
            "Content-Type": "application/json"
        };

        const dropItems = {}
        this.state.divExpenses.map((ele: any) => {
            Object.assign(dropItems, {
                [ele.description]: ele.amount
            });
        });

        const toggleItems = {
            [this.state.dividendPeriod1]: Number(this.state.dividendValue1),
            [this.state.dividendPeriod2]: Number(this.state.dividendValue2),
            [this.state.dividendPeriod3]: Number(this.state.dividendValue3),
            [this.state.dividendPeriod4]: Number(this.state.dividendValue4),
            [this.state.dividendPeriod5]:Number( this.state.dividendValue5),
        }

        const data = {
            dividend: {
                dividend: this.state.dividend,
                toggle: this.state.divideDividends,
                dropdown_items: dropItems,
                toggle_dropdown: toggleItems
            }
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.postDividendId = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.dividendID ? `${configJSON.dividendEndPoint}/${this.state.dividendID}` : configJSON.dividendEndPoint
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.dividendID ? configJSON.examplePatchAPiMethod : configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleValidateOtherInc = (newErrors: any) => {
        if (!/^\d+(\.\d+)?$/.test(this.state.intInterest) && this.state.intInterest !== "" && this.state.intInterest !== null) {
            newErrors.intInterest = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.intDeposit) && this.state.intDeposit !== "" && this.state.intDeposit !== null) {
            newErrors.intDeposit = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.intIT) && this.state.intIT !== "" && this.state.intIT !== null) {
            newErrors.intIT = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.intOtherSrc) && this.state.intOtherSrc !== "" && this.state.intOtherSrc !== null) {
            newErrors.intInterest = 'Please Enter a valid Number';
        }
        this.setState({ alertFieldError: true })
    }
    handleValidateAmountAndDesc = (amountobj: any, descriptionobj: any) => {

        this.state.otherIntAddFields.forEach((element: any, index: any) => {
            console.log("elementelementelement", element);

            if (element.amount === "" || element.amount === undefined) {
                amountobj.push({ index: index, error: "Required!" })
            } else if (!/^\d+(\.\d+)?$/.test(element.amount)) {
                amountobj.push({ index: index, error: "Please Enter a valid Number" })
            }

        });
        this.state.otherIntAddFields.forEach((element: any, index: any) => {
            if (element.description === "" || element.description === undefined) {
                descriptionobj.push({ index: index, error: "Required!" })
            }
        });
    }
    saveOTherInt = () => {
        const newErrors: any = {};
        const amountobj: any = []
        const descriptionobj: any = []
        this.handleValidateAmountAndDesc(amountobj, descriptionobj)

        this.handleValidateOtherInc(newErrors)
        if (Object.keys(newErrors).length === 0 && descriptionobj.length === 0 && amountobj.length === 0) {
            this.setState({
                otherIntIncomeError: {},
                otherIntIncomeDescError: [],
                otherIntIncomeAmterror: []
            })
            const header = {
                'token': localStorage.getItem(configJSON.authToken),
                "Content-Type": "application/json"
            };

            const dropItems = {}
            this.state.otherIntAddFields.map((ele: any) => {
                if (ele.amount !== "" && ele.description !== "") {
                    Object.assign(dropItems, {
                        [ele.description]: ele.amount
                    });
                }
            });

            const data = {
                interest_income: {
                    saving_bank_interest: this.state.intInterest,
                    interest_from_deposits: this.state.intDeposit,
                    interest_from_income_tax_refund: this.state.intIT,
                    interest_from_any_other_source: this.state.intOtherSrc,
                    dropdown_items: dropItems
                }
            }

            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

            this.postInterestId = requestMessage.messageId;


            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
                this.state.interestID.length > 0 ?
                    `${configJSON.interestEndPoint}/${this.state.interestID}` : configJSON.interestEndPoint)
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(data)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                `${this.state.otherInterestData ? configJSON.examplePatchAPiMethod : configJSON.exampleAPiMethod}`
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);

        } else {
            this.setState({
                otherIntIncomeError: newErrors,
                otherIntIncomeDescError: descriptionobj,
                otherIntIncomeAmterror: amountobj
            })
        }



    }

    getDividend = () => {      
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getDividendId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.dividendEndPoint}`)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getOtherExemptIncomes = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getExemptId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.exemptEndPoint}`)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getOtherInt = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getInterestId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.interestEndPoint}`)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleCheckZeroValue = (value: string) => {
        return value === "0.0" ? "" : value
    }

    handleGetOtherIncData = (responseJson: any) => {
        let data = responseJson && responseJson.data && responseJson.data.length > 0;

        if (data) {
            const data = responseJson?.data[0]?.attributes
            const { pension_scheme, family_pension, dropdown_items, pass_through_income, id, unexplained_income, income_from_race_horse, gift_tax_under_section56 } = data
            const { sum_of_money_received, stamp_duty_value_immovable_property, stamp_duty_value_consideration, fair_market_value_without_consideration, fair_market_value_consideration, } = gift_tax_under_section56
            const { receipt, deduction, amount_not_deductible, profit_chargeable, net_income_race_horses, } = income_from_race_horse
            const { cash_credits, unexplained_investment, unexplained_money, undisclosed_investment, unexplained_expenditure, amount_borrowed, } = unexplained_income
            this.setState({
                pension: pension_scheme,
                familyPension: family_pension,
                passThroughInc: {desc: pass_through_income.description, amt: pass_through_income.amount.toString()},
                unexpCashCredits: this.handleCheckZeroValue(cash_credits),
                unexpSection69: this.handleCheckZeroValue(unexplained_investment),
                unexpSection69A: this.handleCheckZeroValue(unexplained_money),
                unexpSection69B: this.handleCheckZeroValue(undisclosed_investment),
                unexpSection69C: this.handleCheckZeroValue(unexplained_expenditure),
                unexpSection69D: this.handleCheckZeroValue(amount_borrowed),
                incRaceHorseReceipts: this.handleCheckZeroValue(receipt),
                incRaceHorseSection57: this.handleCheckZeroValue(deduction),
                incRaceHorseSection58: this.handleCheckZeroValue(amount_not_deductible),
                incRaceHorseSection59: this.handleCheckZeroValue(profit_chargeable),
                incRaceHorseRaceHorse: this.handleCheckZeroValue(net_income_race_horses),
                giftSumOfMoney: this.handleCheckZeroValue(sum_of_money_received),
                giftImmovable: this.handleCheckZeroValue(stamp_duty_value_immovable_property),
                giftConsideration: this.handleCheckZeroValue(stamp_duty_value_consideration),
                giftFairMarket: this.handleCheckZeroValue(fair_market_value_without_consideration),
                giftFairConsideration: this.handleCheckZeroValue(fair_market_value_consideration),
                otherInc:  (dropdown_items && dropdown_items.length) > 0 ? dropdown_items:[
                    {
                        description: '',
                        amount: '',
                        income_type: "other_income"
                    }
                ],
                otherIncDataId: id


            })
        }

    }

    validateField = (fieldName: string) => {
        const { errorGroup, landDetails, exemptAgriInc } = this.state;
        const fieldValue = this.state[fieldName as keyof ErrorMessage];
        if (fieldName === "landDetails") {
            if (parseInt(exemptAgriInc) > 500000 && landDetails.length === 0) {
                errorGroup[fieldName as keyof ErrorMessage] = configJSON.atleatsOnlandDetails;
            } else {
                delete errorGroup[fieldName as keyof ErrorMessage];
            }
        } else if (fieldName === "otherExamptIncome" || fieldName === "otherExamptIncome2") {
            this.validOtherExamptIncom();
        }
        else {
            if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
                errorGroup[fieldName as keyof ErrorMessage] = configJSON.requiredField;
            } else {
                delete errorGroup[fieldName as keyof ErrorMessage];
            }
        }
        this.setState({ errorGroup });
    }

    validOtherExamptIncom = () => {
        const { errorGroup } = this.state;
        const { descriptionErrors, amountErrors } = this.validateOtherExampIncomeForm();
            
        errorGroup.otherExamptIncome = {};
        errorGroup.otherExamptIncome2 = {};
        
        descriptionErrors.forEach((error: boolean, index: number) => {
            if (error) {
                errorGroup.otherExamptIncome[index] = configJSON.otherExamptField;
            }
        });
        
        amountErrors.forEach((error: boolean, index: number) => {
            if (error) {
                errorGroup.otherExamptIncome2[index] = configJSON.otherExamptField;
            }
        });

        if (descriptionErrors.every(error => !error)) {
            errorGroup.otherExamptIncome = {}
        }
        if (amountErrors.every(error => !error)) {
            errorGroup.otherExamptIncome2 = {}
        }
    }

    getExempIncomeValidation = () => {
        const { errorGroup } = this.state;

        let isValidate = true;
        
        delete errorGroup['exemptInt' as keyof ErrorMessage];
        delete errorGroup['exemptAgriExpenses' as keyof ErrorMessage];
        delete errorGroup['exemptAgriInc' as keyof ErrorMessage];

        this.validateField('landDetails');
        this.validateField('otherExamptIncome');
        this.validateField('otherExamptIncome2');

        const hasExemptIntError = !!errorGroup['exemptInt'];
        const hasExemptAgriExpensesError = !!errorGroup['exemptAgriExpenses'];
        const hasExemptAgriIncError = !!errorGroup['exemptAgriInc'];
        
        const otherExamptIncomeHasErrors = Object.keys(errorGroup.otherExamptIncome || {}).length > 0;
        const otherExamptIncome2HasErrors = Object.keys(errorGroup.otherExamptIncome2 || {}).length > 0;
    
        if (
            hasExemptIntError ||
            hasExemptAgriExpensesError ||
            hasExemptAgriIncError ||
            otherExamptIncomeHasErrors ||
            otherExamptIncome2HasErrors
        ) {
            isValidate = false;
        }    

        return isValidate;
    }

    validateFieldForLands = (fieldName: string) => {
        const { landsErrorGroup } = this.state;
        const fieldValue = this.state[fieldName as keyof LandsErrorMessage];
        if (!fieldValue) {
            landsErrorGroup[fieldName as keyof LandsErrorMessage] = configJSON.requiredField;
        } else {
            if (fieldName === "pin_code") {
                if (fieldValue.length < 6) {
                    landsErrorGroup[fieldName as keyof LandsErrorMessage] = configJSON.requiredField;
                } else {
                    delete landsErrorGroup[fieldName as keyof LandsErrorMessage];
                }
            } else {
                delete landsErrorGroup[fieldName as keyof LandsErrorMessage];
            }

        }
        this.setState({ landsErrorGroup });
    }


    getaddLandsValidation = () => {
        let isValidate = false;

        if(this.state.name_of_district || this.state.pin_code || this.state.checkbox || this.state.irrigation){
            isValidate = true;
        }

        return isValidate;
    }

    getLandsErrorMessageByName(name: string) {
        return this.state.landsErrorGroup[name as keyof LandsErrorMessage];
    }


    validateOtherExampIncomeForm = () => {
        const descriptionErrors: boolean[] = [];
        const amountErrors: boolean[] = [];

        this.state.otherExemptIncomes.forEach((item: {
            description: string;
            amount: number | string;
        }, index: number) => {
            const hasDescription = item.description.trim() !== '';
            const hasAmountCond = item && item.amount && item.amount.toString() && item.amount.toString().trim()
            const hasAmount = hasAmountCond !== '';

            descriptionErrors[index] = !hasDescription && hasAmount;
            amountErrors[index] = !hasAmount && hasDescription;
        });

        return { descriptionErrors, amountErrors };
    };


    saveOtherExempt = () => {
        const { errorGroup } = this.state
        const validate = this.getExempIncomeValidation()
        if (validate) {
            this.handleSaveExemptIncome()
        } else {
            this.setState({ errorGroup });
        }
    }

    handleSaveExemptIncome = () => {
        const header = {
            "token": localStorage.getItem(configJSON.authToken),
            "Content-Type": "application/json"
        };

        const dropItems = {}
        this.state.otherExemptIncomes.map((ele: any) => {
            Object.assign(dropItems, {
                [ele.description]: ele.amount
            });
        });

        const data = {
            exampt_income: {
                exampt_interest: this.state.exemptInt,
                agricultural_expenses: this.state.exemptAgriExpenses,
                agricultural_income: this.state.exemptAgriInc,
                lands_attributes: this.state.landDetails,
                dropdown_items: dropItems
            }
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        this.postExemptId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.exemptID.length > 0 ?
                `${configJSON.exemptEndPoint}/${this.state.exemptID}` : configJSON.exemptEndPoint)


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `${this.state.exemptID.length > 0 ? configJSON.examplePatchAPiMethod : configJSON.exampleAPiMethod}`

        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    getErrorMessageByName(name: string) {
        return this.state.errorGroup[name as keyof ErrorMessage];
    }

    getRentErrorMessageByName(name: string) {
        return this.state.rentErrorGroup[name as keyof RentErrorMessage];
    }
    handleValidateGiftTaxUnderSection56Form = (newErrors: any) => {

        if (!/^\d+(\.\d+)?$/.test(this.state.giftSumOfMoney) && this.state.giftSumOfMoney !== "") {
            newErrors.giftSumOfMoney = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.giftImmovable) && this.state.giftImmovable !== "") {
            newErrors.giftImmovable = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.giftConsideration) && this.state.giftConsideration !== "") {
            newErrors.giftConsideration = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.giftFairMarket) && this.state.giftFairMarket !== "") {
            newErrors.giftFairMarket = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.giftFairConsideration) && this.state.giftFairConsideration !== "") {
            newErrors.giftFairConsideration = 'Please Enter a valid Number';
        }
    }
    handleValidateunExplainedIncomeForm = (newErrors: any) => {
        if (!/^\d+(\.\d+)?$/.test(this.state.unexpCashCredits) && this.state.unexpCashCredits !== "") {
            newErrors.unexpCashCredits = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.unexpSection69) && this.state.unexpSection69 !== "") {
            newErrors.unexpSection69 = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.unexpSection69A) && this.state.unexpSection69A !== "") {
            newErrors.unexpSection69A = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.unexpSection69B) && this.state.unexpSection69B !== "") {
            newErrors.unexpSection69B = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.unexpSection69C) && this.state.unexpSection69C !== "") {
            newErrors.unexpSection69C = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.unexpSection69D) && this.state.unexpSection69D !== "") {
            newErrors.unexpSection69D = 'Please Enter a valid Number';
        }
    }
    handleValidateIncomeFromRaceHorseForm = (newErrors: any) => {
        if (!/^\d+(\.\d+)?$/.test(this.state.incRaceHorseReceipts) && this.state.incRaceHorseReceipts !== "") {
            newErrors.incRaceHorseReceipts = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.incRaceHorseSection57) && this.state.incRaceHorseSection57 !== "") {
            newErrors.incRaceHorseSection57 = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.incRaceHorseSection58) && this.state.incRaceHorseSection58 !== "") {
            newErrors.incRaceHorseSection58 = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.incRaceHorseSection59) && this.state.incRaceHorseSection59 !== "") {
            newErrors.incRaceHorseSection59 = 'Please Enter a valid Number';
        }
        if (!/^\d+(\.\d+)?$/.test(this.state.incRaceHorseRaceHorse) && this.state.incRaceHorseRaceHorse !== "") {
            newErrors.incRaceHorseRaceHorse = 'Please Enter a valid Number';
        }
    }
    handleOtherIncomeOtherField = (newErrors: any) => {
        if (this.state.passThroughInc.amt === "") {
            newErrors.passThroughIncAmt = 'Required!';
        } else if (!/^\d+(\.\d+)?$/.test(this.state.passThroughInc.amt)) {
            newErrors.passThroughIncAmt = 'Please Enter a valid Number';
        }
        if (this.state.passThroughInc.amt === "") {
            newErrors.passThroughIncAmt = 'Required!';
        } else if (!/^\d+(\.\d+)?$/.test(this.state.passThroughInc.amt)) {
            newErrors.passThroughIncAmt = 'Please Enter a valid Number';
        }
        if (this.state.passThroughInc.desc === "") {
            newErrors.passThroughInDesc = 'Required!';
        }
    }
    handleValidateOtherIncome = (newErrors: any) => {
        this.handleValidateGiftTaxUnderSection56Form(newErrors)
        this.handleValidateunExplainedIncomeForm(newErrors)
        this.handleValidateIncomeFromRaceHorseForm(newErrors)
        this.handleOtherIncomeOtherField(newErrors)
    }

    validateOtherIncome = () => {
        const descriptionErrors: any = [];
        const amountErrors: any = [];
    
        this.state.otherInc.forEach((element: any, index: number) => {
            const { description, amount } = element;
    
            // Validate description
            if (!description && amount) {
                descriptionErrors.push({ index: index, name: "description", error: "Description is required" });
            }
    
            // Validate amount
            if (description) {
                if (amount === "" || amount === undefined) {
                    amountErrors.push({ index: index, name: "amount", error: "Amount is required" });
                } else if (!/^\d+(\.\d+)?$/.test(amount)) {
                    amountErrors.push({ index: index, name: "amount", error: "Please enter a valid number" });
                }
            }
        });
    
        return { descriptionErrors, amountErrors };
    };

    validatePassThroughInc = () => {
        const descriptionErrors = [];
        const amountErrors = [];
    
        const { desc, amt } = this.state.passThroughInc;
    
        if (amt && (!desc || desc.trim() === "")) {
            descriptionErrors.push("Description is required.");
        }
        if (desc && (!amt || amt.trim() === "")) {
            amountErrors.push("Amount is required.");
        }
    
        return { descriptionErrors, amountErrors };
    };
    
    
    
    
    

    buildDropdownItems = () => {
        return this.state.otherInc
        .filter((ele: any) => ele.description && ele.amount)
        .map((ele: any) => ({
            name: ele.description,
            amount: ele.amount
        }));
    };

    
    buildRequestData = (dropItems: any) => ({
        other_income: {
            pension_scheme: this.state.pension,
            family_pension: this.state.familyPension,
            dropdown_items: dropItems,
            pass_through_incomes: [{name: this.state.passThroughInc.desc, amount: this.state.passThroughInc.amt}],
            unexplained_income_attributes: {
                cash_credits: Number(this.state.unexpCashCredits),
                unexplained_investment: Number(this.state.unexpSection69),
                unexplained_money: Number(this.state.unexpSection69A),
                undisclosed_investment: Number(this.state.unexpSection69B),
                unexplained_expenditure: Number(this.state.unexpSection69C),
                amount_borrowed: Number(this.state.unexpSection69D),
            },
            income_from_race_horse_attributes: {
                receipt: Number(this.state.incRaceHorseReceipts),
                deduction: Number(this.state.incRaceHorseSection57),
                amount_not_deductible: Number(this.state.incRaceHorseSection58),
                profit_chargeable: Number(this.state.incRaceHorseSection59),
                net_income_race_horses: Number(this.state.incRaceHorseRaceHorse),
            },
            gift_tax_under_section56_attributes: {
                sum_of_money_received: Number(this.state.giftSumOfMoney),
                stamp_duty_value_immovable_property: Number(this.state.giftImmovable),
                stamp_duty_value_consideration: Number(this.state.giftConsideration),
                fair_market_value_without_consideration: Number(this.state.giftFairMarket),
                fair_market_value_consideration: Number(this.state.giftFairConsideration),
            }
        }
    });

    
    callSaveOtherIncomeAPI = (data: any) => {
        const header = {
            token: localStorage.getItem(configJSON.authToken),
            "Content-Type": "application/json"
        };
    
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.postIncDetailsId = requestMessage.messageId;
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.state.otherIncDataId
                ? `${configJSON.otherIncEndPoint}/${this.state.otherIncDataId}`
                : configJSON.otherIncEndPoint
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            this.state.otherIncDataId
                ? configJSON.examplePatchAPiMethod
                : configJSON.exampleAPiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    
    saveOtherInc = () => {
        // Validate passThroughInc fields
        const { descriptionErrors: passThroughDescErrors, amountErrors: passThroughAmtErrors } = this.validatePassThroughInc();
    
        // Combine all errors (both otherInc and passThroughInc)
        const { descriptionErrors, amountErrors } = this.validateOtherIncome();
    
        // Check if there are any errors
        if (passThroughDescErrors.length > 0 || passThroughAmtErrors.length > 0 || descriptionErrors.length > 0 || amountErrors.length > 0) {
            this.setState({
                otherIncomeDescError: [...descriptionErrors, ...passThroughDescErrors],
                otherIncomeAmtError: [...amountErrors, ...passThroughAmtErrors],
                snack: true,
                errorType: "error"
            });
        } else {
            // Clear errors and proceed to save
            this.setState({ otherIncomeDescError: [], otherIncomeAmtError: [] });
            
            const dropItems = this.buildDropdownItems();
            const data = this.buildRequestData(dropItems);
    
            this.callSaveOtherIncomeAPI(data);
        }
    };
    
    
    

    getOtherRent = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getRentId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.rentEndPoint}`)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    getOtherInc = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getOtherIncId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.otherIncEndPoint}`)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    addLandFn = () => {
        this.setState({ exemptDialog: false })
    }


    saveOtherRent = () => {
        const validate = this.getRentValidation()
        if (validate) {
            const header = {
                "token": localStorage.getItem(configJSON.authToken),
                "Content-Type": "application/json"
            };

            const data = {
                rent: {
                    dropdown_items: [
                        ...this.state.rent,
                        ...this.state.deduction
                    ]
                }
            }

            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
            this.postRentId = requestMessage.messageId;

            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
                !this.state.rentId ? configJSON.rentEndPoint : `${configJSON.rentEndPoint}/${this.state.rentId}`
            )
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(data)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                this.state.rentId ? configJSON.examplePatchAPiMethod : configJSON.exampleAPiMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    saveLandDetails = (district: any, pincode: any, owner: any, irrigation: any) => {
        const { landsErrorGroup } = this.state;
        const validate = this.getaddLandsValidation()
        if (validate) {
            if (this.state.landId) {
                const landDetails = {}
                Object.assign(landDetails, {
                    name_of_district: district,
                    pin_code: pincode, checkbox: owner, irrigation: irrigation,
                });
                const landArray = [...this.state.landDetails]
                landArray[this.state.landId - 1] = landDetails;
                this.setState({ landId: null, landDetails: landArray, addLandExe: false, name_of_district: '', pin_code: '', irrigation: '', checkbox: '' }, () => {
                    this.getExempIncomeValidation()
                })
            } else {

                const landDetails = {}
                Object.assign(landDetails, {
                    name_of_district: district,
                    pin_code: pincode, checkbox: owner, irrigation: irrigation,
                });
                const landArray = [...this.state.landDetails]
                landArray.push(landDetails)
                this.setState({ landDetails: landArray, addLandExe: false, name_of_district: '', pin_code: '', irrigation: '', checkbox: '' }, () => {
                    this.getExempIncomeValidation()
                })
            }
            this.setState({
                snack: true,
                errorType: configJSON.errorTypeSuccess,
                snackMessage: configJSON.savedSuccessMessage
            })
        } else {
            this.setState({ addLandExe: false, });
        }

    }

    handleDivident = (e: any) => {
        const value = e.target.value;
        if (!/^\d+$/.test(value) && value !== "") {
            this.setState({ dividendError: "Please enter valid number" });
        } else {
            this.setState({ dividendError: null });
        }
        if(value >= 50000) {
        this.setState({ isDividend: true });
        } else {
        this.setState({ isDividend: false, divideDividends: false });
        }
        this.setState({ dividend: value });
    }

    otherExemptIncomesInputValidation = (amount: string | number) => {
        if(amount === null){
            return ""
        }else if(amount === "string"){
            return Number(amount)
        }
        return amount;
    }

    closeIntDialog = () => {
        this.getOtherInt()
       this.setState({ intDialog: false })
    }

    closeDevDialog = () => {
        this.getDividend()
       this.setState({ dividendDialog: false })
    }
    handleDeleteSingleOtherExempt = (dropdownItemId: string | number) => {
        const header = {
            "token": this.state.authToken,
            "Content-Type": configJSON.validationApiContentType
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.deleteSingleOtherExemptApiCallId = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.exemptEndPoint}/${this.state.exemptID}?dropdown_item_id=${dropdownItemId}`)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod

        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    closeOtherIncDialog = () => {
        this.getOtherInc()
       this.setState({ otherDialog: false })
    }
    // Customizable Area End
}