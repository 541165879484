import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";

interface ErrorObject {
    [key: string]: boolean;
}
 export interface CoOwnerObj{
    dbId:number,
    id:string,
    name_of_coowner:string,
    pan_of_coowner:string,
    percentage_share:string,
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    homePage: (success?:boolean) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeStep: number;
    firstInterest: string;
    secondFinYear: string;
    secondTotalInt: string;
    flatNum: string;
    premiseName: string;
    streetName: string;
    pincode: string;
    country: string;
    state: string;
    name_of_coowner: string;
    pan_of_coowner: string;
    percentage_share: string;
    coOwner: any;
    selfHouseID: any;
    error: ErrorObject;
    openPaperIndex: null | string;
    propertyId: undefined | string | number;
    isLoadingSelfProperty: boolean
    snackbarOpenRental: boolean;
    isChecked: boolean
    coOwnerError: boolean;
    saveAsDraft: boolean;
    deleteCoOwnerId: string

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class HousePropertyEditController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    saveSelfFirstID: string = ''
    getDraftFormSelfProperty: string = ""
    getSelfID: string = '';
    getRentalID: string = '';
    deleteCoOwnerSelfApiID:string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            activeStep: 1,
            firstInterest: '',
            secondFinYear: '',
            secondTotalInt: '',
            flatNum: '',
            premiseName: '',
            streetName: '',
            pincode: '',
            country: '',
            state: '',
            name_of_coowner: '',
            pan_of_coowner: '',
            percentage_share: '',
            coOwner: [{
                id: 0,
                name_of_coowner: '',
                pan_of_coowner: '',
                percentage_share: '',
            }],
            selfHouseID: null,
            error: {},
            openPaperIndex: null,
            propertyId: undefined,
            isLoadingSelfProperty: true,
            snackbarOpenRental: false,
            isChecked: false,
            coOwnerError: false,
            saveAsDraft: false,
            deleteCoOwnerId:""
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }



    async componentDidMount() {
        // Customizable Area Start
        if (this.props.id !== "") {
            this.getSingleHousePropertySelf(this.props.id)
        } else {
            this.setState({ isLoadingSelfProperty: false })

        }
        // Customizable Area End
    }




    // Customizable Area Start


    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.saveSelfFirstID === apiRequestCallId) {
                if(this.state.saveAsDraft){
                    this.props.homePage(true)
                }
                this.handleSaveAndNextData(responseJson)
            }

            if (this.getDraftFormSelfProperty === apiRequestCallId) {
                this.handleDraftDataForSelfProperty(responseJson)

            }
            if (this.getSelfID === apiRequestCallId) {
                this.handleDraftDataForSelfProperty(responseJson)
            }
            if(this.deleteCoOwnerSelfApiID == apiRequestCallId) {
                this.deleteCoOwnerResponse(responseJson)
            }

        }

        // Customizable Area End
    }
    deleteCoOwnerResponse = (response:{message:string})=>{
        if(response.message && response.message.includes("success")){
            const coowner = this.state.coOwner.find((coowner:{id:number,dbId:string})=>coowner.dbId==this.state.deleteCoOwnerId)
            coowner && this.handleDeleteMultipleItems(coowner.id,0)
        }
    }

    handleAddMultipleItems = () => {
        let newLength = this.state.coOwner.length;
        if(newLength>0){
            newLength = Math.max(...this.state.coOwner.map((CoOwn:any)=>CoOwn.id))+1
        }
        let newItem = { dbId:0, id: newLength, name_of_coowner: '', pan_of_coowner: '', percentage_share: '' }
        this.setState((prev) => ({
            coOwner: [...prev.coOwner, newItem]
        }));
    };
    handleDeleteMultipleItems = (id: string | number,dbId:number) => {
        if(dbId>0){
            this.deleteCoOwner(id.toString())
        }
        else{
            let newData = this.state.coOwner.filter((item: { id: number | string }) => item.id !== id)
            this.setState(() => ({
                coOwner: newData
            }));
            const panRegex = /[A-Z]{5}\d{4}[A-Z]$/;

            let allValid = true;
            let hasduplicate = false;

            const panFrequency = newData.reduce((acc: {[key: string]: number}, item: {pan_of_coowner: string}) => {
                acc[item.pan_of_coowner] = (acc[item.pan_of_coowner] || 0) + 1;
                return acc;
            }, {});
            const duplicates = newData.filter((item: { pan_of_coowner: string }) => panFrequency[item.pan_of_coowner] > 1 && item.pan_of_coowner!="");
            hasduplicate = duplicates.length>0;
            
            for (const owner of newData) {
                const percentage = String(owner.percentage_share).trim();
                const name = String(owner.name_of_coowner).trim();
                const pan = String(owner.pan_of_coowner).trim();

                if(percentage!=='' && (Number(percentage)<0 || Number(percentage)>100) ){
                    allValid = false;
                    break;
                }  
                if (!owner.name_of_coowner || name === '' ||
                    !owner.percentage_share || percentage === '' ||
                    !owner.pan_of_coowner || pan === '' ||
                    !panRegex.test(owner.pan_of_coowner) || owner.percentage_share < 1 || owner.percentage_share > 100 ) {  
                    allValid = false;
                    break;
                }  
                     
            }
            this.setState({
                error:{
                    ...this.state.error,
                    ["duplicate"] : hasduplicate,
                    ["coOwner"] : !allValid,
                }
            })
        }
    };
    handleMultipleChangeData = (id: string | number, event: React.ChangeEvent<HTMLInputElement>) => {
        let newArr = [...this.state.coOwner];
        const findElementIndex = newArr.findIndex((item: { id: string | number, name_of_coowner: string, pan_of_coowner: string, percentage_share: string }) => item.id === id);

        if (findElementIndex !== -1) {
            if (typeof event.target.value !== 'string') {
                return;
            }
            if ((event.target.name === 'percentage_share' && /[^0-9.]/.test(event.target.value)) || (event.target.name === 'percentage_share' && event.target.value.startsWith('.')) || (event.target.value.match(/\./g) || []).length > 1) {
                return;
            }
            if((event.target.name === 'name_of_coowner' && !/^[a-zA-Z ]*$/.test(event.target.value)) || (event.target.name === "name_of_coowner" && event.target.value.length > 20)) {
                return
            }
            let val = event.target.value.replace(/^\s+/, "");
            val = val.replace(/\s{2,}/g, ' ');
            newArr[findElementIndex][event.target.name] = val;
        }
        this.handleValidateFormFourth();
        this.setState({ coOwner: newArr })
    };

    handleDraftDataForSelfProperty = (apiRes: {

        data: {
            id: string | number,
            attributes: {
                step: number,
                previous_financial_year: string,
                total_interest_amount: string,
                interest_paid_on_housing_loan: string,
                house_address: {
                    house_number: string,
                    premise_name: string,
                    street: string,
                    pincode: string,
                    country: string,
                    state: string
                },
                coowner_details: {
                    id: number,
                    name_of_coowner: string,
                    pan_of_coowner: string,
                    percentage_share: string,
                    house_property_id: number,
                    created_at: string,
                    updated_at: string
                    }[],
            }

        }
    }) => {

        if (apiRes && apiRes?.data) {
            const {
                step,
                previous_financial_year,
                total_interest_amount,
                interest_paid_on_housing_loan,
                house_address,
                coowner_details

            } = apiRes?.data?.attributes
            this.setState({ isLoadingSelfProperty: false })
                this.setState({
                    propertyId: apiRes.data.id,
                    firstInterest: interest_paid_on_housing_loan,
                    secondFinYear: previous_financial_year,
                    secondTotalInt: total_interest_amount,
                        flatNum: house_address?.house_number,
                        premiseName: house_address?.premise_name,
                        streetName: house_address?.street,
                        pincode: house_address?.pincode,
                        country: house_address?.country,
                        state: house_address?.state,
                        coOwner: coowner_details.map((coowner:{
                            id: number,
                            name_of_coowner: string,
                            pan_of_coowner: string,
                            percentage_share: string,
                        })=>{
                            return ({
                                ...coowner,
                                dbId:coowner.id
                            })
                        })
                    });
        }
        else {
            this.setState({ isLoadingSelfProperty: false })
        }

    }
    handleSaveAndNextData = (apiRes: {
        data: {
            attributes: {
                draft_status: string
            }
        }
        meta: {
            message: [
                {
                    id: number,
                    message: string
                }
            ]
        }
    }) => {
        if (apiRes && apiRes.data.attributes.draft_status === "save as draft") {
            let currentId = apiRes.meta.message[0].id
            this.setState((prev) => ({ ...prev, activeStep: prev.activeStep + 1, propertyId: currentId, snackbarOpenRental: true }))
        } else if (apiRes && apiRes.data.attributes.draft_status === "submitted") {
            this.setState({
                snackbarOpenRental:true
            })
            this.props.homePage(true)
        }
    }
    handleSteps = () => {
        if (this.state.activeStep > 1) {
            this.setState((prev) => ({
                ...prev,
                activeStep: prev.activeStep - 1
            }))
        } else {
            this.props.homePage()
        }
    }
    handleSnackbarself = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === configJSON.clickaway) {
            return;
        }

        this.setState({ snackbarOpenRental: false })
    };
    handleCheckBox = () => {
        this.setState({ isChecked: !this.state.isChecked })   
    }
    getSingleHousePropertySelf = (id: string) => {
        const header = {
            "token": localStorage.getItem(configJSON.authToken),
            "Content-Type": configJSON.exampleApiContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSelfID = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.selfPropertyEndPoint}/${id}`);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleOnchangeHouseProperty = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (typeof event.target.value !== 'string') {
            return;
        }
        let val = value.replace(/^\s+/, "");
        val = val.replace(/\s{2,}/g, ' ');
        if (!/^[a-zA-Z ]*$/.test(value) || value.length > 25) {
            return;
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: val
        }))

    }
    handleOnchangeHouseFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if(/[^0-9\b]/.test(value) || value.length > 12) {
            return
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleOnchangeHouseYear = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if(!/^\d{0,4}(-\d{0,2})?$/.test(value) || value.length > 9) {
            return
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleOnchangeHouseAlpha = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let val = value.replace(/^\s+/, "");
        val = val.replace(/\s{2,}/g, ' ');
        if (!/^[a-zA-Z0-9- ]*$/.test(value)) {
            return;
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: val
        }))
    }
    handleOnchangeHousePin = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if(/[^0-9\b]/.test(value) || value.length > 6 || (value.length === 1 && value === '0')) {
            return
        }
        this.setState((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }
    handleFocusHouseProperty = (event: { target: { name: string, value: string } }) => {
        const { name } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: false
            }
        }));
    }
    handleBlurHouseProperty = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState((prevData) => ({
            ...prevData,
            error: {
                ...prevData.error,
                [name]: String(value).trim() === ""
            }
        }));
    }
    handleValidateFormOne = () => {
        return true
    }
    handleValidateFormSecond = () => {
        return true
    }
    handleValidateFormThird = () => {
        let isValid = true;
        const setError = (field: string) => {
            isValid = false;
            this.state.error[field] = true;
        };

        const validateField = (value: string, field: string) => {
            if (!value || value === "") {
                setError(field);
            }
        };
        validateField(this.state.flatNum, "flatNum");
        // validateField(this.state.premiseName, "premiseName");
        // validateField(this.state.streetName, "streetName");
        validateField(this.state.state, "state");
        validateField(this.state.country, "country");
        validateField(this.state.pincode, "pincode");

        if (this.state.pincode?.length < 6) {
            isValid = false
            this.state.error["pincode"] = true;
        }
        return isValid
    }

    handleValidateFormFourth = () => {
        const panRegex = /[A-Z]{5}\d{4}[A-Z]$/;
        const { coOwner } = this.state;

        let allValid = true;
        let hasduplicate = false;

        const panFrequency = this.state.coOwner.reduce((acc: {[key: string]: number}, item: {pan_of_coowner: string}) => {
            acc[item.pan_of_coowner] = (acc[item.pan_of_coowner] || 0) + 1;
            return acc;
          }, {});
        const duplicates = this.state.coOwner.filter((item: { pan_of_coowner: string }) => panFrequency[item.pan_of_coowner] > 1 && item.pan_of_coowner!="");
        hasduplicate = duplicates.length>0;
        
        for (const owner of coOwner) {
            const name = String(owner.name_of_coowner).trim();
            const pan = String(owner.pan_of_coowner).trim();
            const percentage = String(owner.percentage_share).trim();
            
            if (!owner.name_of_coowner || name === '' ||
                !owner.pan_of_coowner || pan === '' ||
                !owner.percentage_share || percentage === '' ||
                !panRegex.test(owner.pan_of_coowner) || owner.percentage_share < 1 || owner.percentage_share > 100 ) {  
                allValid = false;
                break;
            }  
            if(percentage!=='' && (Number(percentage)<0 || Number(percentage)>100) ){
                allValid = false;
                break;
            }       
        }
        this.state.error["duplicate"] = hasduplicate;
        this.state.error["coOwner"] = !allValid;
        return allValid && !hasduplicate;
    };


    handleValidateAllForms = () => {
        switch (this.state.activeStep) {
            case 1:
                return this.handleValidateFormOne()
            case 2:
                return this.handleValidateFormSecond()
            case 3:
                return this.handleValidateFormThird()
            case 4:
                return this.handleValidateFormFourth()
        }

    }
    handleValidationSubmit = (saveAsDraft:boolean=false) => {
        if (this.handleValidateAllForms()) {
            this.setState({
                saveAsDraft: saveAsDraft
            })
            this.saveSelfFirst()
        } else {
            this.setState({
                error: this.state.error
            })
        }
    }

    costumStepperData = () => {
        switch (this.state.activeStep) {
            case 1:
                return {
                    interest_paid_on_housing_loan: this.state.firstInterest,
                    property_type: 'self_occupied',
                    step: 1,
                    draft_status: "save as draft",
                }
            case 2:
                return {
                    step: 2,
                    property_type: 'self_occupied',
                    previous_financial_year: this.state.secondFinYear,
                    total_interest_amount: this.state.secondTotalInt,
                    draft_status: "save as draft",

                }
            case 3:
                return {
                    property_type: 'self_occupied',
                    step: 3,
                    draft_status: "save as draft",
                    house_address: {
                        house_number: this.state.flatNum,
                        premise_name: this.state.premiseName,
                        street: this.state.streetName,
                        pincode: this.state.pincode,
                        country: this.state.country,
                        state: this.state.state,

                    },
                }
            case 4:
                return {
                    step: 4,
                    property_type: 'self_occupied',
                    id: this.state.selfHouseID,
                    coowner_details: this.state.coOwner
                }

        }
    }

    saveSelfFirst = async () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };
        const data = this.costumStepperData();
        if (this.state.propertyId !== "" && this.state.propertyId !== undefined) {
            if (data) {
                data.id = this.state.propertyId;
            }
        }
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.saveSelfFirstID = requestMessage.messageId;

        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.selfPropertyEndPoint)

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handleDraftDataSelfProperty = async () => {
        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDraftFormSelfProperty = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.checkDraftStatus}self_occupied`);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleGetFinYear = (value: any) => {
        this.setState({
            secondFinYear: value.toString()
        })
    }
    deleteCoOwner =  async (id: string) => {
        this.setState({
            deleteCoOwnerId:id
        })
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData(configJSON.authToken)
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const deleteCoOwnerApiURL = `${configJSON.deleteTanentApi}/${id}/delete_coowner_detail`;
        this.deleteCoOwnerSelfApiID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            deleteCoOwnerApiURL
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    // Customizable Area End
}